import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button, CheckBox, Input, BackButton } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginSideGraphic from "components/LoginSideGraphic";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


export default function ConfirmEmailPage() {
    const [status, setStatus] = useState("pending");
    const [message, setMessage] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")
    const userId = searchParams.get("userId")



    useEffect(() => {
        // TODO: More params
        fetch(`${API_ENDPOINT}/confirmEmail?userId=${userId}&code=${token}`, {
            method: "GET",
            credentials: "include",
            headers: {
                'accept': 'text/plain'
            }
        })
            .then((data) => {
                // handle success or error from the server
                if (data.ok) {
                    setStatus("success");
                    setMessage("Email confirmation successful!")
                }
                else {
                    data.json().then((d) => {
                        if (d.detail !== undefined) {
                            setStatus("error");
                            setMessage(d.detail);
                        } else {
                            setStatus("error");
                            setMessage("There was an issue confirming your email. Contact support or try again.");
                        }
                    });
                }
            })
            .catch((error) => {
                // handle network error
                console.error(error);
                setStatus("error");
                setMessage("There was an issue confirming your email. Contact support or try again.");
            });
    }, [userId, token])


    return <>
        <Helmet>
            <title>Confirm Email | Intertwined</title>
            <meta name="description" content="Web site created using create-react-app" />
        </Helmet>
        <div className="flex h-screen overscroll-none overflow-hidden m-0">
            <div className="w-[75%] bg-white-A700 md:w-full relative">
                <TopNavLogin className="absolute top-0 left-0 right-0 z-10" type={"register"} />

                <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center">
                    <div className="gap-3 flex flex-col items-center">
                        <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                            Confirm Email
                        </Heading>
                        <Text as="p" className="!text-gray-900_05">
                            {message}
                        </Text>

                    </div>

                </div>
            </div>

            <div className="w-[25%] md:hidden">
                <LoginSideGraphic />
            </div>
        </div>
    </>
} 