import { Text, Img, Button, Input } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function ForgotPasswordForm() {
    // State variables
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const handleClickBack = () => {
        navigate(-1);
    };

    // Handle submit event for the form
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate email
        if (!email) {
            setError("Please fill in all fields.");
        } else {
            // Clear error message
            setError("");

            // Post data to the /forgotPassword API
            fetch(API_ENDPOINT + "/forgotPassword", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        setSuccess(true);
                        setError("");
                    } else {
                        setError("There was a problem. Please try again later.");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setError("There was a problem. Please try again later.");
                });
        }
    };

    return (
        <div>
            {success ? (
                <div className="text-green-600 text-lg">
                    <p>An email has been sent to {email} with instructions to reset your password.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    {/* Display error message */}
                    {error && <div className="text-red-500 text-sm">{error}</div>}

                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-[23px]">
                            <div className="flex flex-col items-start gap-[5px]">
                                <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                    Your Email
                                </Text>
                                <Input
                                    size="xl"
                                    shape="round"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={setEmail}
                                    className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                                    // Ensure the input allows form submission on Enter
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex gap-8 self-start sm:flex-col">
                            <Button
                                size="5xl"
                                variant="outline"
                                shape="round"
                                leftIcon={
                                    <Img
                                        src="/images/img_arrowdown.svg"
                                        alt="arrow_down"
                                        className="h-[16px] w-[16px]"
                                    />
                                }
                                className="min-w-[161px] gap-0.5 font-semibold shadow-sm sm:px-5 flex justify-center items-center"
                                onClick={handleClickBack}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit" // Ensure the button submits the form
                                size="5xl"
                                shape="round"
                                className="min-w-[367px] border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}
