import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading } from "../../components";
import Header from "../../components/Header";
import StudentSidebar from "../../components/StudentSidebar";
import Sidebar1 from "components/Sidebar1";
import { UserContext } from "UserContext";
import { useNavigate } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function MyProfilePage() {
  const { userInfo, setUserInfo, logout } = useContext(UserContext);
  const navigate = useNavigate();

  // State variables for form inputs
  const [firstName, setFirstName] = useState(userInfo?.firstName || '');
  const [lastName, setLastName] = useState(userInfo?.lastName || '');
  const [username, setUsername] = useState(userInfo?.username || '');
  const [highSchool, setHighSchool] = useState(userInfo?.highSchool || '');
  const [isEditing, setIsEditing] = useState(false); // Toggle between view and edit mode
  const [loading, setLoading] = useState(false); // Loading state for API call
  const [errorMessage, setErrorMessage] = useState(''); // Error message
  const [successMessage, setSuccessMessage] = useState(''); // Success message

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Prepare the data to be sent to the API
    const data = {
      username,
      firstName,
      middleName: '',
      lastName,
      highSchool,
      phoneNumber: userInfo?.phoneNumber || ' ', // assuming phoneNumber is maintained elsewhere
    };

    try {
      const response = await fetch(`${API_ENDPOINT}/api/UserInfo/Edit`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        // Handle errors returned from the server
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to update profile.');
      } else {
        // Update was successful
        const successData = await response.json();
        setSuccessMessage('Profile updated successfully.');

        // Merge the new data with existing userInfo
        const newUserInfo = { ...userInfo, ...data };

        console.log(newUserInfo);

        // Update userInfo in context
        setUserInfo(newUserInfo);

        setIsEditing(false);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('An error occurred while updating your profile.');
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <Helmet>
        <title>My Profile | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700 md:flex-col">
        {userInfo?.role === 'Teacher' ? <Sidebar1 /> : <StudentSidebar />} {/* Conditionally render sidebar */}
        <div className="container-md md:p-5">
          <div className="flex flex-col items-start gap-6 pb-12 md:pb-5">
            <Header className="self-stretch px-4 pb-2 pt-[7px]" />
            <div className="flex w-[83%] pt-[15px] md:w-full">
              <div className="flex w-full flex-col items-start justify-center gap-[13px] px-6 pb-[310px] md:pb-5 sm:px-5">
                <div className="flex">
                  <Heading size="11xl" as="h1" className="!font-poppins !text-gray-900_05">
                    My Profile
                  </Heading>
                </div>

                {/* Display success or error messages */}
                {successMessage && (
                  <div className="text-green-500">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="text-red-500">
                    {errorMessage}
                  </div>
                )}

                {/* Toggle between view and edit mode */}
                {isEditing ? (
                  // Edit mode: display form
                  <form onSubmit={handleSubmit} className="w-full">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center gap-5">
                        <Img
                          src={userInfo?.profileImage?.url}
                          alt="avatar"
                          className="h-[126px] w-[126px] rounded-[50%]"
                        />
                        <div className="flex flex-col w-full">
                          <label className="text-sm font-medium text-gray-700">First Name</label>
                          <div className="border rounded-lg p-3 max-w-[264px]">
                            <input
                              type="text"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="p-2 border border-gray-300 rounded-md"
                              required
                            />
                          </div>

                          <label className="text-sm font-medium text-gray-700 mt-4">Last Name</label>
                          <div className="border rounded-lg p-3 max-w-[264px]">
                            <input
                              type="text"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className=" border border-gray-300 rounded-md"
                              required
                            />
                          </div>


                          <label className="text-sm font-medium text-gray-700 mt-4">Username</label>
                          <div className="border rounded-lg p-3 max-w-[264px]">
                            <input
                              type="text"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              className="p-2 border border-gray-300 rounded-md"
                              required
                            />
                          </div>

                          <label className="text-sm font-medium text-gray-700 mt-4">Institution/Organization</label>
                          <div className="border rounded-lg p-3 max-w-[264px]">
                            <input
                              type="text"
                              value={highSchool}
                              onChange={(e) => setHighSchool(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-4 mt-6">
                        <Button
                          size="3xl"
                          variant="fill"
                          shape="round"
                          className="font-roboto font-medium tracking-[0.10px] sm:px-5"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? 'Saving...' : 'Save Changes'}
                        </Button>
                        <Button
                          size="3xl"
                          variant="outline"
                          shape="round"
                          className="font-roboto font-medium tracking-[0.10px] sm:px-5"
                          onClick={() => setIsEditing(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  // View mode: display user information
                  <div className="flex w-[51%] items-center justify-between gap-5 rounded-lg border border-solid border-blue_gray-100 p-4 md:w-full md:px-5 sm:p-5">
                    <Img
                      src={userInfo?.profileImage?.url}
                      alt="avatar"
                      className="h-[126px] w-[126px] rounded-[50%]"
                    />
                    <div className="flex flex-col items-start w-full">
                      <div className="flex w-full items-center gap-2">
                        <Text as="p" className="!font-medium tracking-[0.10px] !text-gray-900_02">
                          <h1 className="text-xl">{userInfo?.firstName} {userInfo?.lastName}</h1>
                        </Text>
                      </div>
                      <h2 className="text-sm">{userInfo?.username}</h2>
                      <h2 className="text-sm">{userInfo?.email}</h2>
                      <h2 className="text-sm">{userInfo?.highSchool}</h2>
                    </div>
                  </div>
                )}

                {/* Edit Profile and Logout Buttons */}
                <div className="flex gap-4 mt-4">
                  {!isEditing && (
                    <Button
                      size="3xl"
                      variant="fill"
                      shape="round"
                      className="font-roboto font-medium tracking-[0.10px] sm:px-5"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit Profile
                    </Button>
                  )}
                  <Button
                    size="3xl"
                    variant="outline"
                    shape="round"
                    className="font-roboto font-medium tracking-[0.10px] sm:px-5"
                    onClick={handleLogout}
                  >
                    Log Out
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
